
<template>
  <div>
    <header-slot>
      <template #actions>
        <b-row class="justify-content-end mr-1">
          <b-button @click="newappoitment" variant="outline-primary"
            >Add New Appointment</b-button
          >
        </b-row>
      </template>
    </header-slot>

    <calendar-appointment tab="4" />

    <modal-new-event
      v-if="opencreate"
      @hide="closecreate"
      :type="'event'"
      state="0"
    ></modal-new-event>
  </div>
</template>

<script>
import CalendarAppointment from "@/views/crm/views/calendar/components/appointment/default.vue";
import ModalNewEvent from "@/views/crm/views/calendar/components/appointment/modals/ModalNewEvent.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
    "calendar-appointment": CalendarAppointment,
    "modal-new-event": ModalNewEvent,
  },
  data() {
    return {
      opencreate: false,
    };
  },

  methods: {
    newappoitment() {
      this.opencreate = true;
    },
    closecreate() {
      this.opencreate = false;
      // this.changeMonth(0);
      // this.functionGlobal();
    },
  },
};
</script>

<style>
</style>